import React, { useState, useEffect, useReducer } from 'react';
import './styles/App.css';
import { PageLayout } from './components/PageLayout';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { RunLog } from './components/RunLog';
import { InteractionType } from "@azure/msal-browser";
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import Select from 'react-select';
import { useMsal } from "@azure/msal-react";


function ErrorComponent({ error }) {
    return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const { accounts } = useMsal();

    const [runLogIdsList, setRunLogIds] = useState(() => {
        var rawLogIds = localStorage.getItem("runLogIds")
        return !!rawLogIds ? rawLogIds.split(',') : []
    });

    const [minColumnWidth, setMinColumnWidth] = useState(() => {
        return localStorage.getItem("storedColWidth") || 425;
    });

    const outingsSettingsOptions = [
        { value: 'Driver', label: 'Driver Notes' },
        { value: 'Change', label: 'Change Notes' },
        { value: 'Tire', label: 'Tire Info' },
        { value: 'Lap', label: 'Lap Info' },
        { value: 'Weather', label: 'Weather Info' },
        { value: 'Changes', label: 'Setup Changes' }
    ]
    const [outingsListSettings, setOutingsListSettings] = useState([outingsSettingsOptions[0].value]);

    const [selectedEvent, setSelectedEvent] = useState("");
    const [EventsList, setEventsList] = useState(null);
    const [selectedSession, setSelectedSession] = useState("");
    const [SessionsList, setSessionsList] = useState(null);
    const [reverseOutingOrder, setReverseOutingOrder] = useState(false);


    const Events = EventsList && EventsList.map((Event) =>
        <option key={Event.eventId}>
            {Event.name}
        </option>);

    const Sessions = SessionsList && SessionsList.map((Session) =>
        <option key={Session.sessionId}>
            {Session.name}
        </option>);

    useEffect(() => {
        const debouncedForceReload = debounce(() => {
            forceUpdate()
        }, 100)

        window.addEventListener('resize', debouncedForceReload)
    }, [])

    function debounce(fn, ms) {
        let timer;
        return _ => {
            clearTimeout(timer);
            timer = setTimeout(_ => {
                timer = null;
                fn.apply(this, arguments);
            }, ms);
        };
    }

    const defaultSelections = () => {
        var storedSelections = localStorage.getItem("storedSelections");

        if (storedSelections) {
            var splitSelections = storedSelections.split(',')
            setOutingsListSettings(splitSelections)
            return outingsSettingsOptions.filter(x => splitSelections.includes(x.value))
        } else {
            return outingsSettingsOptions[0];
        }
    }

    function AddRunLog() {
        var newId = uuid();

        //Set the local storage of the new Id to the same values as the old one to copy over any settings
        if (runLogIdsList && runLogIdsList.length - 1 > -1) {
            var prevRunLogId = runLogIdsList[runLogIdsList.length - 1]
            if (prevRunLogId) {
                localStorage.setItem(newId, localStorage.getItem(prevRunLogId))
            }

        }
        var newIds = [...runLogIdsList, newId];

        localStorage.setItem("runLogIds", newIds)
        setRunLogIds(newIds);
    }

    const DeleteRunLog = (id) => {
        var newIds = runLogIdsList.filter(x => x !== id);
        localStorage.setItem("runLogIds", newIds);
        localStorage.removeItem(id);
        setRunLogIds(newIds);
    }

    //Callback function for updating the selected and available top level selections
    const UpdateTopLevelSelections = (event, eventsList, session, sessionsList, reverseOutings) => {

        if (event) {
            setSelectedEvent(event);
        }

        if (eventsList) {
            setEventsList(eventsList);
        }

        if (session) {
            setSelectedSession(session);
        }

        if (sessionsList) {
            setSessionsList(sessionsList);
        }

        if (reverseOutings) {
            setReverseOutingOrder(reverseOutings); //TODO: Make this happen on uncheck too? 
        }
    }

    const SettingsCheckChange = event => {
        if (event !== undefined) {
            const selectedEvents = event.map(event => event.value)
            localStorage.setItem("storedSelections", selectedEvents)
            setOutingsListSettings(selectedEvents)
        }
    }

    const EventChange = event => {
        if (event.target.value !== undefined && event.target.value !== "Events") {
            setSelectedEvent(event.target.value);

            //Update these values in all of the stored values for each run log
            runLogIdsList.forEach(element => {
                var savedSelections = localStorage.getItem(element)
                var storageObj = JSON.parse(savedSelections)

                storageObj.Event = event.target.value;

                localStorage.setItem(element, JSON.stringify(storageObj))
            });

        }
    }

    const SessionChange = event => {
        if (event.target.value !== undefined && event.target.value !== "Sessions") {
            setSelectedSession(event.target.value);

            //Update these values in all of the stored values for each run log
            runLogIdsList.forEach(element => {
                var savedSelections = localStorage.getItem(element)
                var storageObj = JSON.parse(savedSelections)

                storageObj.Session = event.target.value;

                localStorage.setItem(element, JSON.stringify(storageObj))
            });
        }
    }

    
    const ReverseCheckChange = event => {
        if (event.target.checked !== undefined) {
            setReverseOutingOrder(event.target.checked);

            //Update these values in all of the stored values for each run log
            runLogIdsList.forEach(element => {
                var savedSelections = localStorage.getItem(element)
                var storageObj = JSON.parse(savedSelections)

                storageObj.reverseOutingOrder = event.target.checked;

                localStorage.setItem(element, JSON.stringify(storageObj))
            });
        }
    }


    function RunLogsList() {
        var cols = []
        var rows = []

        var actualScreenWidth = document.body.clientWidth * window.devicePixelRatio;
        var numColsPerRow = Math.floor(actualScreenWidth / minColumnWidth); //We want each column to have at least 425 pixels of width
        if (numColsPerRow === 0) numColsPerRow = 1; //But have at least 1

        for (let i = 0; i < runLogIdsList.length; i++) {
            var runLogProps = {}
            runLogProps.id = runLogIdsList[i];
            runLogProps.deleteFunction = DeleteRunLog;
            runLogProps.outingsListSettings = outingsListSettings
            runLogProps.savedSelections = localStorage.getItem(runLogIdsList[i])

            if (i == 0) {
                runLogProps.updateTopLevelSelections = UpdateTopLevelSelections
            } else {
                runLogProps.UpdateTopLevelSelections = null
            }
            // <RunLog id={runLogId} deleteFunction={DeleteRunLog} outingsListSettings={outingsListSettings} savedSelections={localStorage.getItem(runLogId)} UpdateTopLevelSelections={null} />

            cols.push(
                <Col className='runLog' key={runLogIdsList[i]}>
                    <RunLog {...runLogProps} />
                </Col>)
            if ((i + 1) % numColsPerRow === 0) {
                rows.push(<Row>{cols}</Row>)
                cols = []
            }
        }

        //Add last uneven row if necessary
        if (cols.length > 0) {
            rows.push(<Row>{cols}</Row>)
        }

        return rows;
    }

    const isTrackhouse = () => {
        if (accounts[0]) {
            if (accounts[0].username.includes("trackhouse")) {
                return true;
            }
        }

        return false;
    }


    const MinColumnWidthTextChange = event => {
        if (event.target.value !== undefined) {
            setMinColumnWidth(event.target.value)
            localStorage.setItem("storedColWidth", event.target.value)
        }
    }

    function refreshPage() {
        window.location.reload(false);
    }

    return (
        <MsalAuthenticationTemplate
            // interactionType={InteractionType.Redirect}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent} >
            {isTrackhouse() && <Container fluid>
                <Row style={{ display: 'flex', marginBottom: '8px', marginTop: '-24px' }}>
                    <Col xs={1}>
                        <Button onClick={AddRunLog}>Add Run Log</Button>
                    </Col>
                    <Col xs={1} style={{ alignItems: 'center' }}>
                        <span style={{ fontSize: 10 }}>Min. Column Width: </span>
                        <input style={{ fontSize: 12, width: 30 }} value={minColumnWidth} type="text" onChange={MinColumnWidthTextChange} size="sm" placeholder='Min. Width'></input>
                    </Col>
                    <Col xs={3}>
                        <Row>
                            <Col>
                                <Form.Select className="runLog-button" value={selectedEvent} onChange={EventChange} size="sm">
                                    <option>Events</option>
                                    {Events}
                                </Form.Select>
                                <Form.Select className="runLog-button" value={selectedSession} onChange={SessionChange} size="sm">
                                    <option>Sessions</option>
                                    {Sessions}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Button onClick={refreshPage}>Apply</Button>
                                <Form.Check className="runLogCheck" type="checkbox"style={{ width: 110 }} label="Reverse Outings" onChange={ReverseCheckChange} checked={reverseOutingOrder} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6}>
                        <Select styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: 'slategray',
                                borderColor: 'slategray'
                            }),
                            menuList: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: 'slategray',
                                color: 'black'

                            })
                        }} isMulti defaultValue={defaultSelections} closeMenuOnSelect={false} onChange={SettingsCheckChange} options={outingsSettingsOptions} />
                    </Col>
                </Row>
                {RunLogsList()}
            </Container>}
        </MsalAuthenticationTemplate>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}